import "~/utils/modernizr";
import App from "~/components/App";

// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log("Vite ⚡️ Rails");

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log("Visit the guide for more information: ", "https://vite-ruby.netlify.app/guide/rails");

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

const exists = (selector) => document.querySelectorAll(selector);

const preload = (selector, callback) => {
  const elements = exists(selector);

  if (elements.length !== 0) {
    callback()
      .then((module) => module.default)
      .then((instanceModule) => {
        $(elements).each((index, element) => {
          const instance = new instanceModule(element);
          element.classList.add("ic-lazyloaded");
          instance.render();
        });
      });
  }
};

const app = new App();

document.addEventListener("DOMContentLoaded", () => {
  app.render();

  // Frontend
  preload(".frontend-landing-index-container", () => import("../pages/frontend/Landing/Index.js"));
  preload(".frontend-articles-show-container", () => import("../pages/frontend/Articles/Show.js"));
  preload(".frontend-repositories-show-container", () => import("../pages/frontend/Repositories/Show.js"));
  preload(".frontend-interviews-index-container", () => import("../pages/frontend/Interviews/Index.js"));
  preload(".frontend-interviews-show-container", () => import("../pages/frontend/Interviews/Show.js"));
  preload(".frontend-guide-index-container", () => import("../pages/frontend/Guide/Index.js"));
  preload(".frontend-front_pages-valuation-container", () => import("../pages/frontend/FrontPages/Valuation.js"));
  preload(".frontend-front_pages-advisors-container", () => import("../pages/frontend/FrontPages/Advisors.js"));
  preload(".frontend-advisors-index-container", () => import("../pages/frontend/Advisors/Index.js"));
  preload(".frontend-advisors-funnel-index-container", () => import("../pages/frontend/Advisors/Funnel/Index.js"));
  preload(".frontend-advisors-funnel-step-0-container", () => import("../pages/frontend/Advisors/Funnel/Step0.js"));
  preload(".frontend-advisors-search-show-container", () => import("../pages/frontend/Advisors/Search/Show.js"));
  preload(".frontend-advisors-show-container", () => import("../pages/frontend/Advisors/Show.js"));
  preload(".frontend-static-pages-page-container", () => import("../pages/frontend/StaticPages/Page.js"));
  preload(".frontend-static-pages-error-container", () => import("../pages/frontend/StaticPages/Error.js"));
  preload(".frontend-seminars-index-container", () => import("../pages/frontend/Seminars/Index.js"));
  preload(".frontend-valuation-funnel-index-container", () => import("../pages/frontend/Valuation/Funnel/Index.js"));
  preload(".frontend-fiscality-index-container", () => import("../pages/frontend/Fiscality/Index.js"));

  // Dashboard
  preload(".dashboard-settings-index-container", () => import("../pages/dashboard/Settings/Index.js"));
  preload(".dashboard-settings-password-container", () => import("../pages/dashboard/Settings/Password.js"));
  preload(".dashboard-transactions-show-container", () => import("../pages/dashboard/Transactions/Show.js"));

  preload(".modal-login", () => import("../components/modals/Login.js"));
  preload(".modal-reset-password", () => import("../components/modals/ResetPassword.js"));
  preload(".modal-phone", () => import("../components/modals/Phone.js"));
  preload(".modal-guide", () => import("../components/modals/Guide.js"));
  preload(".modal-video", () => import("../components/modals/Video.js"));
  preload(".modal-advisor-world-map", () => import("../components/modals/AdvisorWorldMap.js"));
  preload(".modal-advisor-team-member", () => import("../components/modals/AdvisorTeamMember.js"));
  preload(
    ".modal-dashboard-transactions-filters",
    () => import("../components/modals/DashboardTransactionsFilters.js")
  );
  preload(".modal-advisors-search-filters", () => import("../components/modals/AdvisorsSearchFilters.js"));
  preload(
    ".modal-dashboard-transactions-booking",
    () => import("../components/modals/DashboardTransactionsBooking.js")
  );
  preload(".modal-delete-account", () => import("../components/modals/DeleteAccount.js"));
  preload(".modal-validate-phone", () => import("../components/modals/ValidatePhone.js"));

  preload(".infographic-1", () => import("../components/infographics/Infographic1.js"));
  preload(".infographic-2", () => import("../components/infographics/Infographic2.js"));
  preload(".infographic-3", () => import("../components/infographics/Infographic3.js"));
  preload(".infographic-4", () => import("../components/infographics/Infographic4.js"));
  preload(".infographic-5", () => import("../components/infographics/Infographic5.js"));
  preload(".infographic-6", () => import("../components/infographics/Infographic6.js"));
  preload(".infographic-7", () => import("../components/infographics/Infographic7.js"));
  preload(".editor-upsell-component-form", () => import("../components/infographics/Upsell.js"));
  preload(".editor-barometer-component-form", () => import("../components/infographics/Barometer.js"));
  preload(".editor-fees-component-form", () => import("../components/infographics/Fees.js"));

  // Admin
  preload(".admin-authorizations-form-container", () => import("../components/admin/AuthorizationsForm.js"));
  preload(".admin-google-preview-container", () => import("../components/admin/GooglePreview.js"));
  preload(".admin-cf-news-operation-preview-container", () => import("../components/admin/CfNewsOperationPreview.js"));
  preload(".admin-headline-validator-container", () => import("../components/admin/HeadlineValidator.js"));
  preload(".admin-content-validator-container", () => import("../components/admin/ContentValidator.js"));
  preload(".admin-article-form", () => import("../components/admin/ArticleForm.js"));
  preload(".admin-interview-form", () => import("../components/admin/InterviewForm.js"));
  preload(".admin-hubspot-sync-form", () => import("../components/admin/HubspotSyncForm.js"));
  preload(".admin-hubspot-sync-preparation-form", () => import("../components/admin/HubspotSyncPreparationForm.js"));
  preload(
    ".admin-databases-logos-edit-logos-container",
    () => import("../components/admin/DatabasesLogosEditLogos.js")
  );
  preload(".admin-leads-statistics-container", () => import("../components/admin/LeadsStatistics.js"));
  preload(".admin-sync-statistics-container", () => import("../components/admin/SyncStatistics.js"));
  preload(
    ".admin-databases-sync-cf_news-operations-index-container",
    () => import("../components/admin/OperationsIndex.js")
  );

  preload(".block-menu-container", () => import("../components/BlockMenu.js"));
  preload(".ic-tiptap-container", () => import("../components/Tiptap.js"));
  preload(".ic-tooltip", () => import("../components/Tooltip.js"));
  preload(".ic-collapsable-container", () => import("../components/Collapsable.js"));
  preload(".ic-select.ic-component", () => import("../components/SelectSingleton.js"));
  preload(".ic-url-check-status-container", () => import("../components/UrlCheck.js"));
  preload(".ic-nested-elements-container", () => import("../components/NestedElements.js"));
});
